<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">Edit {{ sectionName }}</span>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <form>
                            <div class="form-group mt-0 contactGroup">
                                <input
                                    class="inputContact"
                                    v-validate="'required'"
                                    type="text"
                                    name="addonsectionname"
                                    autofocus
                                    @blur="checkNameExists()"
                                    required
                                    v-model="editaddonsection.name"
                                    :maxlength="addonsectionname"
                                    id="section_edit_section"
                                />
                                <span class="highlightContact"></span>
                                <span class="barContact"></span>
                                <label class="labelContact">
                                    Add-On Section Name
                                    <sup>★</sup>
                                </label>
                                <span class="invalid-feedback-form" v-show="errors.has('addonsectionname')" style="display:inline;">{{errors.first('addonsectionname')}}</span>
                                <span class="invalid-feedback-form" style="display: inline;" v-show="isAddonNameExists && editaddonsection.name.trim()">Add-On Section Name Exists</span>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{ addonsectionname - editaddonsection.name.length}}/ {{ addonsectionname }}</p>
                            </div>
                            <div class="form-group contactGroup">
                                <textarea
                                    class="inputContact mt-3"
                                    type="text"
                                    v-model="editaddonsection.description"
                                    :maxlength="addonsectiondesc"
                                    required
                                    style="padding: 5px 10px 2px 0px!important;margin-top: 16px!important;"
                                    id="desc_edit_section"
                                ></textarea>
                                <span class="highlightContact"></span>
                                <label class="labelContact">Description</label>
                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{ addonsectiondesc - editaddonsection.description.length}}/ {{ addonsectiondesc }}</p>
                            </div>
                        </form>
                        <div class="v-modal-footer text-center mt-8">
                            <a class="btn btn-link btn-smm" @click="cancelSection">CANCEL</a>
                            <button class="btn btn-secondary btn-smm text-uppercase" @click="save()">
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import addonSection from './mixins/addonsection'
import { EventBus } from './eventBus/nav-bar-event'
export default {
    props: ['modal_name'],
    mixins: [addonSection],
    data() {
        return {
            addonsectionname: 45,
            addonsectiondesc: 1000,
            sectionName: '',
            editaddonsection: {
                name: '',
                description: '',
                status: ''
            },
            isAddonNameExists: false
        }
    },
    methods: {
        cancelSection() {
            this.editaddonsection = {
                name: '',
                description: '',
                status: ''
            }
            this.$modal.hide(this.modal_name)
        },
        checkNameExists() {
            this.$http
                .post('/add_ons/section/check', {
                    name: this.editaddonsection.name,
                    _id: this.editaddonsection._id
                })
                .then(response => {
                    if (response.data.status_id == 0) {
                        this.isAddonNameExists = true
                    } else {
                        this.isAddonNameExists = false
                    }
                })
        },
        save() {
            this.$validator.validateAll().then(result => {
                if (result && !this.isAddonNameExists) {
                    this.saveEditAddonSection()
                }
            })
        }
    },
    created() {
        const dict = {
            custom: {
                addonsectionname: {
                    required: () => 'Addon Section Name is Required'
                }
            }
        }
        this.$validator.localize('en', dict)
    },
    mounted() {
        EventBus.$on('editSectionDetails', sectionId => {
            this.$http
                .post('/add_ons/section/get_addon_section_by_id', {
                    section_id: sectionId
                })
                .then(res => {
                    this.editaddonsection = res.data.response.section_details
                    this.sectionName = res.data.response.section_details.name
                })
        })
    }
}
</script>
